.container {
  padding: calc(var(--mainGapPx) * 2);
  /* display: grid;
  grid-template-rows: max-content max-content max-content;
  grid-template-columns: 120px 1fr;
  text-align: justify; */
  background-color: #f6f6f6;
}

.header {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.position {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
}

.name {
  font-weight: 500;
  font-size: 24px;
}

.review {
  margin-top: 24px;
  /* font-size: 16px; */
  font-size: var(--defaultSize);
}

.position,
.company {
  font-size: 14px;
}
.company {
  margin-top: 10px;
}
.position__text {
  padding: 6px 10px;
  font-weight: 500;
  background-color: white;
  border-radius: 100px;
}

.ReviewStyle {
  position: absolute;
  top: -20px;
  z-index: 0;
  /* display: block; */
  /* width: 124px;
  height: 124px; */

  /* position: absolute;
  top: -183px;
  left: -61px;
  z-index: 0;
  display: block;
  width: 124px;
  height: 124px; */
  /* background-image: url('../../images/ReviewPattern.svg'); */
  /* content: ' '; */
}
/* .NavigationButton {
  margin-top: 24px;
} */
@media (max-width: 1919px) {
  .container {
    /* grid-template-rows: 15px 30px 55px; */
    /* grid-template-columns: 100px 1fr; */
  }

  .name {
    font-size: 18px;
  }

  .review::before {
    top: -140px;
    left: -38px;
    width: 80px;
    height: 80px;
    background-size: 80px;
  }
}

@media (max-width: 1365px) {
  .container {
    /* grid-template-rows: 14px 25px 51px; */
    /* grid-template-columns: 90px 1fr; */
  }

  .name {
    font-size: 14px;
  }

  .name {
    font-size: 14px;
    line-height: 17px;
  }

  .company {
    font-size: 10px;
    line-height: 12px;
  }

  .review::before {
    top: -136px;
    left: -43px;
    width: 92px;
    height: 92px;
    background-size: 92px;
  }
}
