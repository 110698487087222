.container {
  margin: auto;
  /* для адаптации */
  /* margin-top: calc(var(--margin) * 0.5px); */
  /* padding: var(--marginPx) 60px; */
  padding: var(--paddingSectionTop) var(--paddingSectionLeft);
  padding-top: calc(var(--paddingSectionTop) * 1.5);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header h2 {
  width: 69%;
  /* font-size: 68px; */
}

.video::-webkit-media-controls-panel {
  display: none;
}

.videobox {
  position: relative;
  width: 70%;
}

.bottombox__left {
  display: flex;
  flex-direction: column;
  gap: var(--mainGapPx);
  width: 30%;
}

.bottombox {
  /* position: relative; */
  display: flex;
  gap: var(--mainGapPx);
  /* justify-content: end; */
  /* padding-left: 20%; */
  /* height: 100%; */
}

.video {
  /* width: 80%; */
  width: 100%;
  height: 100%;
  /* height: auto; */
  /* height: 550px; */
}

.main {
  display: flex;
  gap: calc(var(--mainGapPx) * 1);
  justify-content: space-between;
  margin: calc(var(--mainGapPx) * 3) 0;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  /* justify-content: start; */
  align-content: flex-start;
}

.tags div {
  text-align: left;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
}

.imgbox {
  height: 350px;
  border-radius: 10px;
}

.card {
  display: flex;
  flex-direction: column;
  gap: var(--mainGapPx);
  padding: 40px 35px;
  background-color: #faf9f9;
  border-radius: 10px;
  transition: all 0.5s;
}

.logoSkolkovo {
  height: 60px;
  background-image: url('../../images/LogoSkolkovo.png');
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.box {
  display: flex;
  flex-direction: column;
  gap: var(--mainGapPx);
  width: 100%;
  padding: calc(var(--mainGapPx) * 2);
  background-color: #faf9f9;
  border-radius: 10px;
  transition: all 0.5s;
}

.box:hover {
  background-color: var(--blue30);
}

.box:hover .number {
  color: white;
}

/* 
.box{
  display: flex;
}
.box:nth-child(2n) {
  margin-top: calc(var(--mainGapPx) * 5);
} */
.number {
  color: var(--blue30);
  font-weight: 400;
  font-size: 100px;
  letter-spacing: -5px;
}

/* .text {
  font-weight: 400;
  font-size: 24px;
} */
.box__btns {
  display: flex;
  align-items: center;
}

.card__btns {
  display: flex;
  align-items: center;
}

.card:hover {
  background-color: #4d5759;
}

.card:hover .logoSkolkovo {
  background-color: #b1ec52;
  background-image: none;
  background-size: 100%;
  mask-size: auto 100%;
  mask-image: url('../../images/LogoSkolkovo.png');
  mask-repeat: no-repeat;
}

@media (max-width: 1920px) {
  .main {
    margin-top: calc(var(--mainGapPx) * 3);
    margin-bottom: var(--mainGapPx);
  }

  .number {
    font-size: 80px;
  }
}

@media (max-width: 1690px) {
  /* .main {
    margin-top: calc(var(--mainGapPx)* 2);
  } */
  .box {
    padding: calc(var(--mainGapPx) * 1);
  }
  .logoSkolkovo {
    background-size: 100% auto;
  }

  .card:hover .logoSkolkovo {
    mask-size: 100% auto;
  }
}

@media (max-width: 1440px) {
  .header h3 {
    font-size: 100px;
  }

  .main > div:first-child {
    /* gap: calc(var(--mainGap) * 1.5px); */
  }

  .main h4 {
    font-size: 70px;
  }

  .video {
    height: auto;
    /* height: 370px; */
  }
  .logoSkolkovo {
    height: 100px;
  }
  .main {
    /* flex-direction: column; */
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(2, 1fr);
  }

  .bottombox__left {
    display: grid;
    grid-template-rows: repeat(2, auto);
    /* grid-template-columns: repeat(3, 1fr); */
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    margin-top: calc(var(--mainGapPx) * 1);
  }

  .bottombox__left > div:nth-child(1) {
    grid-column: span 2;
  }

  .bottombox {
    flex-direction: column;
  }

  .videobox {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .header h3 {
    font-size: 70px;
  }

  .main > div {
    min-width: 300px;
  }

  .main h4 {
    font-size: 50px;
  }

  .main {
    margin: calc(var(--mainGap) * 2.5px) 0 0;
  }
  .logoSkolkovo {
    height: 68px;
    /* background-size: 100% auto; */
  }

  .card:hover .logoSkolkovo {
    /* mask-size: 100% auto; */
  }
}
@media (max-width: 800px) {
}

@media (max-width: 680px) {
  .header {
    gap: 25px;
  }

  .header h2 {
    width: 57%;
  }

  .main {
    grid-template-columns: 1fr;
  }

  .bottombox__left {
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr;
  }

  .bottombox__left > div:nth-child(1) {
    grid-column: span 1;
  }

  .logoSkolkovo {
    background-size: auto;
  }

  .card:hover .logoSkolkovo {
    mask-size: auto;
  }
}

@media (max-width: 524px) {
  .logoSkolkovo {
    background-size: auto;
    background-size: 100% auto;
  }

  .card:hover .logoSkolkovo {
    mask-size: 100% auto;
  }
}
