.header {
  position: fixed;
  top: 0;
  z-index: 50;
  display: flex;
  width: 100vw;
  padding: 0 var(--paddingSectionLeft);
  color: white;
  font-weight: 600;
  background-color: transparent;
  transition: height 0.5s;
  /* background-image: url(../../../images/HeaderMain3.png); */
}

.header.small {
  position: fixed;
  top: 0;
  height: 105px;
  color: black;
  background-color: white;
  border-bottom: #e5e5e5;
  box-shadow: 0px 4px 30px 0px #0000000f;
  /* background-color: rgb(10 10 10); */
  /* background-color: rgb(5 14 32); */
}

.header.small .logo {
  background-image: url('../../../images/HeaderLogo1.svg');
  /* background-image: url('../../../images/HeaderLogoBlack1.svg'); */
  /* mask: url('../../../images/HeaderLogoWhite1.svg');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  background-color: red; */
  /* background-blend-mode: hue; */
}

.header .logo {
  margin-right: 60px;
  background-position: 0;
}

.logo {
  display: block;
  width: 160px;
  height: calc(80px + 40px * 2);
  background-image: url('../../../images/HeaderLogoWhite1.svg');
  background-repeat: no-repeat;
  background-position: 0 40px;
  background-size: contain;
  transition: width 0.5s, height 0.5s, background-size 0.5s;
}

.logo:focus {
  outline: none;
}

.small .logo {
  width: 114px;
  height: 67px;
  /* height: 56px; */
  /* background-size: 114px 56px; */
}

.itemsContainer {
  display: flex;
  justify-content: space-between;
  margin-left: 300px;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
}

.ulmain {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
}

.nav li {
  /* margin: 0 8px; */
  padding: 8px 24px;
  list-style: none;
}

.nav a {
  color: inherit;
  font-weight: 500;
  text-decoration: none;
  list-style: none;
}

/* .nav li.login {
  margin-top: -4px;
  padding: 0;
} */

/* .line {
  display: block;
  width: 1px;
  height: 38px;
  margin: 0 24px;
  background-color: #2c2c2c;
} */

.contacts {
  display: flex;
  gap: var(--mainGapPx);
  align-items: center;
}

.phone {
  /* padding: 40px 55px; */
  font-weight: 500;
  font-size: var(--defaultSize);
}

.menu {
  display: none;
}

.item {
  cursor: pointer;
}

.item:hover {
  background-color: rgba(68, 85, 161, 0.15);
}

@media (max-width: 1365px) {
  /* .btnphone {
    display: none;
  } */

  .header {
    height: 75px;
    /* height: 96px;
    padding: 32px 60px; */
  }

  .itemAppear {
    display: block;
  }

  .ulmain {
    flex-direction: row-reverse;
    justify-content: flex-start;
  }

  .item {
    display: none;
  }

  .mobileMenu .item {
    display: flex;
  }

  .contacts {
    margin-top: 2px;
  }

  .mobileMenu {
    position: absolute;
    /* top: 16px; */
    top: 60px;
    right: 32px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    color: black;
    background-color: white;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  }

  .mobileMenu > * {
    display: flex;
    align-items: center;
    /* width: 207px; */
    width: 350px;
    height: 51px;
    /* padding: 0 18px; */
    border-top: 1px solid #e5e5e5;
    cursor: pointer;
  }

  .mobileMenu li:hover {
    /* font-weight: var(--bold); */
  }

  .mobileMenu li:first-child {
    border-top: none;
  }

  .mobileMenu a {
    color: black;
    /* font-weight: var(--light); */
    font-size: 12px;
  }

  .menu {
    position: relative;
    display: block;
    padding: 10px 17px 10px 31px;
    /* padding: 10px 17px; */
    /* color: var(--blue); */
    color: inherit;
    /* font-weight: var(--bold); */
    background-image: url('../../../images/HeaderMenu.svg');
    background-repeat: no-repeat;
    background-position: 15px 15px;
    border: 1px solid white;
    border-radius: 2px;
    outline: none;
    filter: brightness(0) invert(1);
  }

  .small .menu {
    filter: brightness(0) invert(0);
  }

  .logo {
    height: 55px;
    /* width: 130px; 
     height: 26px; */
  }
}

@media (max-width: 1200px) {
  .header.small {
    height: 75px;
  }

  .small .logo {
    width: 160px;
    height: 55px;
  }
}

@media (max-width: 1024px) {
  .header .logo {
    margin-right: 0;
  }

  .ulmain {
    width: 0;
  }
}

@media (max-width: 767px) {
  .header,
  .header.small {
    height: 60px;
    padding: 0 20px;
  }

  .logo {
    /* background-size: 57px 28px; */
    width: 57px;
    height: 28px;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .small .logo {
    width: 57px;
    height: 28px;
    /* background-size: 114px 56px; */
  }

  .phone {
    display: none;
  }

  /* .btnphone {
    display: none;
  } */

  /* .phone,
  .mail,
  .line {
    display: none;
  } */

  .mobileMenu {
    top: 60px;
    right: 12px;
    width: 130px;
    border-radius: 0 0 5px 5px;
  }

  .mobileMenu > * {
    width: 100%;
  }

  .mobileMenu li {
    /* width: calc(100% - 18px); */
  }

  .menu {
    padding: 16px;
    font-size: 0;
    background-image: url('../../../images/HeaderMenuMobile.svg');
    background-position: 8px;
    border: none;
  }
}

/* @media (max-width: 600px) {
  .mobileMenu {
    width: 130px;
  }
} */
