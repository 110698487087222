.container {
  margin: auto;
  margin-top: calc(var(--margin) * 0.5px);
  padding: var(--paddingSectionTop) var(--paddingSectionLeft);
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: calc(var(--mainGapPx) * 3);
}

.header__tag {
}

.grid {
  display: grid;
  /* grid-template-rows: repeat(3, 1fr); */
  /* grid-template-rows: repeat(3, minmax(auto, max-content)); */
  grid-template-columns: repeat(12, 1fr);
  column-gap: calc(var(--mainGapPx) * 1);
  row-gap: calc(var(--mainGapPx) * 1.5);
}

.h2 {
  margin-left: 50px;
}

@media (max-width: 1440px) {
}

@media (max-width: 1280px) {
  /* .grid {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(12, 1fr);
  } */
}
