.sectionDP {
  /* padding-top: 10px; */
  margin: auto;
  padding: var(--paddingSectionTop) var(--paddingSectionLeft);
  padding-top: calc(var(--paddingSectionTop) * 1.5);
  padding-bottom: 0;
}

.detailitem__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: calc(var(--paddingSectionTop) * 0.75);
  margin-bottom: var(--paddingSectionTop);
}

.detailitem__title h2 {
  font-weight: 500;
  /* font-size: 58px; */
  font-size: 48px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: var(--paddingSectionTop);
  column-gap: calc(var(--mainGapPx) * 1);
  /* row-gap: calc(var(--mainGapPx) * 1.75); */
  row-gap: calc(var(--mainGapPx) * 1);
}

.card {
  display: flex;
  flex-direction: column;
  gap: var(--mainGapPx);
  justify-content: space-between;
  /* padding: var(--index); */
  padding: calc(var(--mainGapPx) * 2);
  background-color: #faf9f9;
  border-radius: 10px;
}

.arrow {
  align-self: flex-end;
}

.card.active {
  color: white;
  background-color: var(--blue30);
}

.grid > div {
  grid-column: span 4;
}

.grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.card__tag {
  color: var(--blue30);
  /* margin-bottom: var(--mainGapPx); */
}

.list li + li {
  margin-top: calc(var(--mainGapPx) * 0.75);
}

.list {
  padding-left: calc(var(--mainGapPx) * 1.5);
}

.boxitem {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: calc(var(--mainGapPx) * 1.5);
  background-color: #faf9f9;
  border-radius: 10px;
}

.boxitem__Id {
  color: #a2a4aa;
  font-size: 14px;
  line-height: 10px;
}

.boxitem__Title {
  font-weight: 500;
  font-size: 16px;
  /* width: 30%; */
  /* width: 10rem; */
}

.boxitem__Text {
  font-weight: 400;
  font-size: 14px;
}

.grid1 .class1 {
  grid-row: 3 / span 2;
  grid-column: 9 / span 4;
}

.grid2 .class2 {
  grid-row: 2 / span 2;
  grid-column: 9 / span 4;
}

.grid3 .class3 {
  grid-row: 2 / span 3;
  grid-column: 5 / -1;
}

.class2 {
  /* grid-column: 9 / span 4;
  grid-row: 2 / span 2; */
}

.class3 {
  /* grid-column: 5 / span 8;
  grid-row: 2 / span 3; */
}

@media (max-width: 1500px) {
  .grid > div {
    grid-column: span 6;
  }

  .grid1 div:nth-child(3) {
    grid-column: 7 / 13;
  }

  .grid1 div:nth-child(4) {
    grid-row: 2 / 2;
  }

  .grid1 .class1 {
    grid-column: 7 / span 6;
  }

  .grid2 .active {
    grid-row: 2 / 3;
    grid-column: 1 / span 6;
  }

  .grid2 .class2 {
    grid-row: 2 / span 3;
    grid-column: 7 / span 6;
  }

  .grid3 .active {
    grid-column: 7 / -1;
  }
  .grid3 div:nth-child(4) {
    grid-row: 2 / 2;
  }
  .grid3 .class3 {
    grid-row: 3 / span 2;
    grid-column: 7 / -1;
  }

  .card {
    padding: calc(var(--mainGapPx) * 1.5);
  }

  .boxitem__Text {
    /* display: none; */
  }

  .boxitem {
    gap: 10px;
  }
}

@media (max-width: 1100px) {
  .boxitem__Id {
    font-size: 12px;
  }

  .detailitem__title h2 {
    width: 80%;
    font-size: 22px;
  }

  .maintag {
    font-size: 10px;
  }

  .grid > div {
    grid-row: auto !important;
    grid-column: span 12 !important;
  }

  /* .grid1 .active,
  .grid1 div:nth-child(3),
  .grid1 .class1 {
    grid-column: span 12;
  } */
}

@media (max-width: 700px) {
  .detailitem__title h2 {
    width: 100%;
    font-size: 18px;
  }
}
