.button {
  padding: 12px 30px;
  font-size: var(--defaultSize);
}
.button.filled {
  color: #fff;
  background-color: var(--blue30);
  border-radius: 2px;
  box-shadow: 0px 13px 19px rgba(68, 85, 161, 0.1);
  cursor: pointer;
}

.button.filled:hover {
  background-color: var(--blue);
}

.button.plain {
  color: var(--blue30);
  font-weight: 500;
  /* background-color: #fff; */
  border: 1px solid var(--blue30);
  /* border-radius: 2px; */
}

.button.plain.white {
  color: white;
  background-color: transparent;
  border: 1px solid white;
}
.button.plain.main {
  color: var(--blue30);
  background-color: white;
  border: 1px solid var(--blue30);
}
.button.plain:hover {
  background-color: #e3e6f1;
}
.button.plain.white:hover {
  color: white;
  background-color: #688aea;
  border: 1px solid #688aea;
}
.round {
  border-radius: 100px !important;
}
