.sectionDP {
  /* padding-top: 10px; */
}

.grid {
  row-gap: calc(var(--mainGapPx) * 1.75);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: calc(var(--mainGapPx) * 1);
  row-gap: calc(var(--mainGapPx) * 1.75);
}

.card {
  display: flex;
  flex-direction: column;
  gap: var(--mainGapPx);
  justify-content: space-between;
  /* padding: var(--index); */
  padding: calc(var(--mainGapPx) * 2);
  background-color: #faf9f9;
  border-radius: 10px;
}

.arrow {
  align-self: flex-end;
}

.card.active {
  color: white;
  background-color: var(--blue30);
}

.grid > div {
  grid-column: span 6;
}

.grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.card__tag {
  color: var(--blue30);
  /* margin-bottom: var(--mainGapPx); */
}

.list li + li {
  margin-top: calc(var(--mainGapPx) * 0.75);
}

.list {
  padding-left: calc(var(--mainGapPx) * 1.5);
}

.boxitem {
  /* display: flex;
  justify-content: space-between; */
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  padding: calc(var(--mainGapPx) * 1.5);
  background-color: #faf9f9;
  border-radius: 10px;
}

.boxitem + .boxitem {
  margin-top: calc(var(--mainGapPx) * 0.5);
}

.boxitem__Id {
  grid-column: span 1;
  padding: 10px;
  color: #a2a4aa;
  font-size: 10px;
  line-height: 10px;
  border-color: #e5e5e5;
}

.boxitem__Title {
  grid-column: span 5;
  font-weight: 500;
  font-size: 14px;
  /* width: 30%; */
  /* width: 10rem; */
}

.boxitem__Text {
  grid-column: span 6;
  font-weight: 400;
  font-size: 12px;
}

.class7 {
  grid-column: span 7 !important;
}

.class2 {
  grid-column: span 5 !important;
}

@media (max-width: 1500px) {
  .card {
    padding: calc(var(--mainGapPx) * 1.5);
  }

  .boxitem__Text {
    display: none;
  }

  .class7 {
    grid-column: span 5 !important;
  }

  .class2 {
    grid-column: span 7 !important;
  }

  .boxitem {
    gap: 10px;
  }

  .boxitem__Title {
    grid-column: span 11;
  }
}
