.card {
  display: flex;
  flex-direction: column;
  grid-column: span 4;
  padding: 40px;
  border-bottom: 1px solid #eeeeee;
  border-radius: 10px;
}

.card.img {
  position: relative;
  /* background-color: #f9f9f9; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* .card.img::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  content: '';
} */

.card.active {
  padding: 40px;
  background-color: var(--blue30);
  border-bottom: none;
}

.card.img .views,
.card.img .title,
.card.img .text,
.card.img .header__date,
.card.active .views,
.card.active .title,
.card.active .text,
.card.active .header__date {
  color: white;
}

.card.active .views path {
  stroke: white;
}

.card.big {
  /* grid-row: span 2; */
  grid-column: 4/-1;
  aspect-ratio: 16/9;
  /* grid-column: span 8; */
  /* grid-row: 2;
  grid-column: 2; */
}

.card.big .title {
  width: 600px;
}

.header {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.header__box {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 4px;
}

.arrow {
  width: 19px;
  height: 19px;
}

.card.active .arrow line,
.card.active .arrow path {
  stroke: white;
}

.header__date {
  padding: 10px 20px;
  color: #878787;
}

.header__date,
.header__tag {
  /* font-size: 14px; */
  font-size: calc(var(--defaultSize) - 2px);
}

.title {
  width: 290px;
  margin-top: 8%;
  margin-bottom: 40px;
  font-weight: 500;
  /* font-size: 20px; */
  font-size: 28px;
  line-height: 120%;
  letter-spacing: -2%;
}

.text {
  /* letter-spacing: -1px; */
  max-width: 500px;
  margin-bottom: 40px;
  font-weight: 400;
  /* font-size: 16px; */
  font-size: var(--defaultSize);
  line-height: 25px;
}

.views {
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 300;
  font-size: 50px;
}

.timebox {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.time {
  color: white;
  font-size: 14px;
}

@media (max-width: 1440px) {
  .card.big {
    grid-column: 1/-1;
  }

  .title {
    font-size: 28px;
  }
}

@media (max-width: 1280px) {
  /* .card.big {
    grid-row: span 1;
    grid-column: span 4;
  } */
  .text {
    line-height: 20px;
  }
  .views {
    font-size: 30px;
  }

  .title,
  .card.big .title {
    width: 100%;
  }
}

@media (max-width: 920px) {
  /* .card.big {
    grid-column: span 6;
  } */

  .card {
    grid-column: span 6;
  }
}

@media (max-width: 650px) {
  /* .card.big {
    grid-column: span 12;
  } */

  .card {
    grid-column: span 12;
  }
}
