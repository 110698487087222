.wrapper {
  position: relative;
}
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 334px;
  height: 52px;
  border: 1px solid #f6f6f6;
  border-radius: 2px;
}

.openContainer {
  border-bottom: none;
  border-radius: 2px 2px 0 0;
}
.incorrectContainer {
  border: 1px solid var(--red);
}
.input {
  width: 100%;
  height: 100%;
  padding: 16px;
  color: #2c2c2c;
  font-weight: var(--light);
  /* background: white; */
  background: #f6f6f6;
  outline: none;
}
.container.fullWidth,
.container.fullWidth input {
  width: 100%;
}
.filledInput {
  font-weight: var(--bold);
}

.arrow {
  position: absolute;
  top: 18px;
  right: 16px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.arrowUp {
  transform: rotate(180deg);
}

.list {
  position: relative;
  right: 1px;
  z-index: 1;
  width: calc(100% + 2px);
  padding: 0 16px;
  list-style-type: none;
  background-color: var(--white);
  border: 1px solid #e5e5e5;
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.item {
  display: flex;
  align-items: center;
  height: 52px;
  border-top: 1px solid #e5e5e5;
  cursor: pointer;
}

.item:hover {
  font-weight: var(--bold);
}

.errorContainer {
  position: absolute;
}
.errorContainer p {
  color: var(--red);
  font-size: 12px;
}

@media (max-width: 1919px) {
  .input {
    padding: 10px 16px;
  }
  .container {
    width: 235px;
    height: 39px;
  }
  .arrow {
    top: 15px;
    right: 8px;
    width: 10px;
    height: 10px;
  }

  .item {
    height: 39px;
  }

  .errorContainer p {
    font-size: 10px;
  }
}

@media (max-width: 1365px) {
  .input {
    padding: 10px 12px;
  }
  .container {
    width: 203px;
    height: 36px;
  }
  .arrow {
    top: 12px;
  }

  .item {
    height: 36px;
  }
}

@media (max-width: 767px) {
  .container {
    width: 100%;
  }
}
