.container {
  /* height: calc(100vh - 105px); */
  /* note: без шапки */
  height: calc(100vh);
  margin: auto;
  /* padding: var(--marginPx) 60px; */
  padding: var(--paddingSectionTop) var(--paddingSectionLeft);

  /* position: relative; */

  background-color: black;
  background-image: url('../../images/bg3.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.container h1 {
  width: 700px;
  /* margin: calc(15 * var(--index)) 0 90px; */
  margin: 300px 0 90px;
  color: white;
  font-weight: 500;
  font-size: 58px;
}

.bottomContainer {
  display: flex;
  /* gap: 10px; */
}

.container p {
  width: 500px;
  padding-left: 15px;
  border-left: 8px solid black;
}

.detailbtn {
  width: 340px;
  padding: 25px 30px;
  font-weight: 500;
  font-size: 18px;
  /* font-size: var(--defaultSize); */
}

@media (max-width: 1440px) {
  .container h1 {
    margin-top: 250px;
  }
  .detailbtn {
    width: 240px;
    font-size: 16px;
  }
}
@media (max-width: 1024px) {
  .container h1 {
    width: 500px;
    margin-top: 120px;
    margin-bottom: 50px;
    font-size: 38px;
  }

  .detailbtn {
    width: 200px;
    padding: 15px 20px;
    font-size: 15px;
  }
}

@media (max-width: 950px) {
  .container h1 {
    width: 100%;
  }
}
