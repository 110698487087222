.container {
  position: relative;
  height: 800px;
  margin: 0 auto;
  margin-bottom: calc(var(--margin) * 2px);
  padding: calc(var(--margin) * 2px) 60px 0;
}

.header {
  display: flex;
  justify-content: space-between;
}

.h2 {
  width: 515px;
}

.itemcontainer {
  position: relative;
  height: 500px;
  /* margin-left: 300px;
    overflow: hidden; */
  /* grid-template-columns: repeat(12, 1fr);
  column-gap: calc(var(--mainGapPx) * 1); */
  margin-left: 33%;
  overflow: hidden;
}

.wrapper {
  display: flex;
  gap: 10px;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.item {
  position: relative;
  top: calc(var(--mainGapPx) * 4);
  flex: 0 0 calc(50% - 10px);
  /* transform: translateY(-50%); */
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  /* width: 586px; */
  /* grid-column: span 4; */
}

/* .item.visible {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}

.item.hidden {
  opacity: 0;
  transform: translate(0, 0) scale(0.8);
} */
@media (max-width: 1440px) {
  .h2 {
    width: 30%;
  }

  .itemcontainer {
    /* height: auto; */
    height: 70%;
    margin-left: 15%;
  }
}

@media (max-width: 1280px) {
  .itemcontainer {
    margin-left: 0;
  }
}

@media (max-width: 900px) {
  .item {
    flex-basis: 100%;
  }

  .wrapper {
    gap: 0px;
  }
}

@media (max-width: 700px) {
  .h2 {
    width: 50%;
  }
}
