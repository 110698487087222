.tag {
  width: fit-content;
  height: fit-content;
  /* margin: 5px 0; */
  padding: 10px 20px;
  font-weight: 500;
  font-size: var(--defaultSize);
  font-family: Montserrat;
  line-height: 21.94px;
  letter-spacing: -4%;
  text-align: center;
  border: 1px solid black;
  border-radius: 500px;
}
.main {
}
.plain {
}
.white {
  color: white;
  border-color: white;
}

.filled.white {
  color: black;
  background-color: white;
}
.filled.second {
  color: var(--blue30);
  background-color: #f0f3ff;
}

.grey {
  color: #a2a4aa;
  border-color: #a2a4aa;
}
.filled.grey {
  color: black;
  background-color: #faf9f9;
  border-color: #faf9f9;
}

.second {
  background-color: #f1f5ff;
  border-color: #f1f5ff;
}

.withHover {
}

/* .button:hover {
  color: var(--blue);
}
.button:hover svg path{
  fill: var(--blue);
} */
@media (max-width: 1440px) {
  .tag {
    padding: 5px 10px;
  }
}
