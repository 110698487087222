.container {
  /* position: absolute; */
  top: 660px;
  right: var(--paddingSectionLeft);
  left: var(--paddingSectionLeft);
  /* position: absolute;
  top: 550px;
  right: 0;
  left: 250px; */
  display: flex;
  gap: calc(var(--mainGapPx) * 3);
  justify-content: space-between;
  padding-top: calc(var(--mainGapPx) * 4);
}

.steps {
  width: 100%;
}

.current {
  width: 80px;
}

.text {
  display: flex;
  align-items: end;
  margin-bottom: 20px;
}

.main {
  color: var(--blue30);
  font-size: 24px;
}

.greytext {
  margin-left: 5px;
  color: #a1a4aa;
  font-size: 14px;
  line-height: 24px;
}

.btns {
  display: flex;
  gap: 10px;
}

/* .line {
  background-color: #D6DBE5;
  height: 1px;
}

.activeline {
  background-color: var(--blue30);
  height: 4px;
} */

.line {
  display: flex;
  width: 100%;
}

.item {
  width: 100%;
  height: 1px;
  background-color: #d6dbe5;
}

.current {
  height: 4px;
  margin-top: -2px;
  background-color: var(--blue30);
}
