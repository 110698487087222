.container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  /* margin-top: var(--paddingSectionLeft); */
  padding: var(--paddingSectionLeft);
}

.container > div {
  width: 50%;
  border-radius: 10px;
  /* height: 850px; */
}

.itemActive {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 60px 90px;
  background-color: var(--blue30);
}

.header {
  /* width: 550px; */
  width: 70%;
  color: white;
}

.itemActive p {
  /* width: 630px; */
  width: 87%;
  color: #e3e8ff;
  font-size: var(--defaultSize);
}

.tag {
  margin-bottom: 50px;
  font-weight: 500;
  font-size: 16px;
}

.itemImg {
  padding: 75px 125px;
  background-image: url('../../images/FormContactUs.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 50px;
  background-color: white;
}

/* .agree {
  margin: 30px 0;
} */
@media (max-width: 1440px) {
  .itemImg {
    padding: 75px 50px;
  }
}

@media (max-width: 800px) {
  .container {
    flex-direction: column;
  }

  .container > div {
    width: 100%;
  }

  .itemActive {
    padding: 60px;
  }

  .tag {
    margin-bottom: 40px;
  }

  .container h2 {
    margin-bottom: 40px;
  }
}
