.dropdown {
  position: relative;
}

.dropdownTitle {
  cursor: pointer;
}

.dropdownContent {
  position: absolute;
  z-index: 1;
  min-width: 350px;
  margin-top: 20px;
  color: black;
  background-color: white;
}

.dropdownItem {
  display: block;
  padding: 20px 15px !important;
  text-decoration: none;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #f1f1f1;
}

.dropdownItem:hover li {
  font-weight: 600;
  background-color: #f1f1f1;
}

@media (max-width: 1365px) {
  .dropdownContent {
    /* right: 0; */
    top: 0;
    left: -316px;
    min-width: 315px;
    margin-top: 0;
  }

  .dropdownItem {
    padding: 20px !important;
  }

  .dropdownItem li {
    padding: 0;
    /* font-size: 14px; */
    font-size: var(--defaultSize);
  }
}
@media (max-width: 765px) {
  .dropdownContent {
    top: 0;
    left: -250px;
    width: 250px;
    min-width: 100px;
    margin-top: 0;
  }

  .dropdownItem {
    padding: 15px !important;
  }
  .dropdownItem li {
    padding: 0;
    /* font-size: 12px; */
  }
}
