.container {
  margin: auto;
  /* padding: 0 60px;
  margin: var(--marginPx) auto; */
  /* padding: var(--marginPx) 60px; */
  /* margin-top: calc(var(--margin)* 0.5px); */
  padding: var(--paddingSectionTop) var(--paddingSectionLeft);
  padding-top: calc(var(--paddingSectionTop) * 1.5);
  padding-bottom: 0;
}

.container h2 {
  /* margin-bottom: var(--marginPx); */
  margin-bottom: calc(var(--marginPx) * 0.5);
  /*font-weight: 500;
  font-size: 58px;
  text-transform: uppercase; */
}

.container h3 {
  margin-bottom: calc(var(--mainGap) * 1px);
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
}

.tabs {
  display: flex;
  gap: calc(var(--mainGap) * 0.5px);
  justify-content: space-between;
  padding-bottom: calc(var(--mainGap) * 1px);
}

.activeTab {
  /* background-color: gray !important; */
  color: var(--blue30);
  border-bottom: solid 2px var(--blue30) !important;
}

.tabs button {
  padding: 23px 35px;
  font-weight: 600;
  /* width: 100%; */
  /* width: 290px; */
  /* padding: 9px; */
  /* background-color: rgb(202, 202, 202); */
  font-size: var(--defaultSize);
  border-bottom: transparent solid 2px;
}

.tabs button:hover {
  color: var(--blue30);
}

.sections {
  display: flex;
  /* padding-top: 10px; */
}

.sectionsLeft {
  /* min-width: 540px; */
  min-width: 370px;
  padding: calc(var(--margin) * 0.5px);
  background-color: #faf9f9;
}

.sectionsRight {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px; 
  align-items: start;
  align-content: flex-start; */
  width: 100%;
  /* padding: calc(var(--mainGap) * 3px) calc(var(--mainGap) * 3px) calc(var(--mainGap) * 3px); */
  padding: 0 calc(var(--mainGapPx) * 1);
  background-color: white;
  border-radius: 10px;
  /* height: 500px; */
}

.sectionWrapper + .sectionWrapper {
  margin-top: var(--mainGap);
}

/* .sectionsRightWrapper, */
.sectionWrapper {
  display: flex;
  align-items: center;
  /* height: 65px; */
  height: 75px;
  padding: var(--mainGap);
  /* height: 88px; */
}

/* .sectionsRightWrapper { */
/* border-bottom: 1px solid #f5f5f5; */
/* } */

.sectionWrapper {
  /* border-bottom: 1px solid #dadada; */
}

/* .sectionsRightWrapper button, */
.sectionWrapper button {
  width: 100%;
  padding: 20px;
  padding-right: 10px;
  padding-left: 0;
  font-weight: 600;
  font-size: var(--defaultSize);
  text-align: left;
}

.sectionWrapperActive {
  color: var(--blue30);
  border-bottom: 1px solid var(--blue30);
}

.sectionWrapper:hover button {
  color: var(--blue30);
}

/* .sectionsRightImg {
  width: 100%;
} */

.grid2 {
  row-gap: calc(var(--mainGapPx) * 1) !important;
}

.grid2 > div {
  /* grid-column: span 6 !important; */
}

.grid2 img {
  margin-top: 20px;
}

@media (max-width: 1690px) {
  .tabs button {
    padding: 10px 25px;
  }
}
@media (max-width: 1200px) {
  .tabs button {
    padding: 10px;
  }
  .sectionsLeft {
    min-width: 250px;
  }
}
