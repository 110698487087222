.btn {
  position: absolute;
  top: 50%;
  left: 50%;
  /* right: 25%; */
  display: flex;
  align-items: center;
  padding: 8px;
  /* width: 385px; */
  background-color: white;
  border-radius: 100px;
  transform: translate(-50%, -50%);
}

.text {
  margin: 0;
  margin-right: var(--mainGapPx);
  padding-left: var(--mainGapPx);
  font-size: var(--defaultSize);
}

.playButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  color: white;
  font-size: 16px;
  /* background-color: #F55477; */
  background-color: var(--blue30);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.playButton svg {
  width: 16px;
  padding-left: 2px;
}

@media (max-width: 680px) {
  .btn {
    width: 210px;
    height: 50px;
  }

  .text {
    margin-right: 0;
  }

  .playButton {
    width: 40px;
    height: 40px;
  }

  .playButton svg {
    width: 10px;
  }
}
