.footer {
  position: relative;
  /* padding-top: 134px; */
  /* background: #f9f9f9; */
  /* font-size: 22px; */
  font-size: var(----lgSize);
}

.container {
  margin: 0 var(--paddingSectionLeft);
  padding: 110px 0px;
  border-top: 1px solid #d6dbe5;
  /* padding: 172px 208px 131px 130px; */
}

/* .container::before {
  content: "";
  display: block;
  position: relative;
  top: -110px;
  padding: 0 60px;
  width: 100%;
  height: 2px;
  background-color: #D6DBE5;
} */

.list {
  display: flex;
  gap: 15%;
  justify-content: space-between;
  width: 80%;
  /* display: grid; */
  /* grid-column: 2; */
  /* grid-template-columns: 1fr 1fr 1fr; */
  list-style-type: none;
}

.list li {
  margin-bottom: 16px;
  list-style-type: none;
}

.logo {
  display: block;
  /* width: 280px; */
  width: 100%;
  /* width: 212px; */
  /* height: 104px; */
  /* padding: 0; */
  /* background-image: url('../../images/HeaderLogo.svg'); */
  /* background-repeat: no-repeat; */
}

.title {
  /* font-size: 26px; */
  margin-bottom: var(--lgSize);
  font-weight: 600;
  font-size: var(--lgSize);
}

.list a {
  display: block;
  margin-bottom: 14px;
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.icons {
  margin-top: 14px;
}

.item + .item {
  margin-top: 40px;
}

/* .logoContainer+li {
  margin-left: calc(var(--margin)*2px);
} */

@media (min-width: 1921px) {
  .footer {
    /* font-size: 22px; */
  }
}

@media (max-width: 1400px) {
  .list {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .logo {
    width: 175px;
  }
}

@media (max-width: 670px) {
  .container {
    padding: 70px 0;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* flex-direction: column; */
    gap: 20px;
  }

  .list > li:nth-child(1) {
    grid-column: span 2;
  }
}
