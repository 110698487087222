.container {
  display: inline-block;
  width: 100%;
  margin: var(--mainGapPx) 0;
  margin-top: calc(var(--margin) * 0.5px);
  padding: 0 60px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: calc(var(--mainGapPx) * 3) 0;
  border-bottom: 1px solid #d6dbe5;
}

.clients {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  margin: calc(1.75px * var(--mainGap)) 0;
}

.clients img {
  max-width: 180px;
  height: 80px;
  object-fit: contain;
  opacity: 0.7;
  filter: grayscale(100%);
  transition: all 0.3s;
}

.clients img:hover {
  opacity: 1;
  filter: grayscale(0%);
}

@media (max-width: 1500px) {
  .clients img {
    max-width: 180px;
    height: 60px;
  }
}

@media (max-width: 1240px) {
  .clients img {
    height: 40px;
  }

  .header {
    padding: calc(var(--mainGapPx) * 2) 0;
  }
}

@media (max-width: 1024px) {
  .clients {
    margin: calc(1.5px * var(--mainGap)) 0;
  }

  .clients img {
    /* height: 40px; */
  }
}

@media (max-width: 900px) {
  .clients {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    /* grid-template-rows: repeat(4, 1fr); */
    /* flex-wrap: wrap; */
    /* justify-content: flex-start; */
    gap: 15px;
  }

  .clients img {
    /* height: 50px; */
    width: 100%;
    height: auto;
    object-fit: contain;
    /* opacity: 1;
    filter: grayscale(0%); */
  }
}

@media (max-width: 550px) {
  .clients {
    grid-template-columns: repeat(4, 1fr);
  }
  .header {
    gap: 15px;
  }
}
