/* .scrollUp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}

.scrollUpActive {
  opacity: 1;
}

.scrollUpSvg {
  width: 50px;
  height: 50px;
}

.scrollUpSvgPath {
  stroke: #838383;
} */

.scrollUp {
  position: fixed;
  right: 50px;
  bottom: 50px;
  /* bottom: 100px; */
  z-index: 1000;
  width: 50px;
  height: 50px;
  margin: -5px;
  color: #838383;
  background: white;
  border-radius: 50px;
  box-shadow: inset 0 0 0 1.5px #838383;
  transform: translateY(25px);
  visibility: hidden;
  cursor: pointer;
  opacity: 0;
  transition: 0.2s;
}
.scrollUpSvg {
}
.arrowIcon {
  position: absolute;
  top: 0;
  top: 50%;
  left: 0;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: #838383;
  transform: translate(-50%, -50%);
}

.scrollUpActive {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.scrollUpSvgPath {
  box-sizing: border-box;
  transition: 0.2s;
  fill: none;
  stroke: var(--blue);
  stroke-width: 1px;
}

.scrollUp.scrollUpActive:hover {
  color: var(--blue) !important;
  background-color: #f5f5f5;
  /* box-shadow: inset 0 0 0 1.5px var(--blue); */
}

.scrollUp.scrollUpActive:hover .scrollUpSvgPath {
  /* stroke: var(--blue); */
}
.scrollUp.scrollUpActive:hover .arrowIcon path {
  stroke: var(--blue);
}

@media (max-width: 1440px) {
  .scrollUp {
    bottom: 30px;
  }
}
