.HeaderItem {
  cursor: pointer;
}

.HeaderItem:hover {
  background-color: rgba(68, 85, 161, 0.15);
}

.HeaderItem:hover > * {
  font-weight: 600;
}
/* .item {
  border-radius: 5px;
  cursor: pointer;
}

.item:hover {
  background-color: rgba(68, 85, 161, 0.15);
}

@media (max-width: 1365px) {
  .item {
    border-radius: 0;
  }
  .active {
    position: relative;
    display: flex;
    font-weight: var(--bold) !important;
  }

  .active::before {
    position: relative;
    top: 4px;
    right: 7px;
    display: block;
    width: 7px;
    height: 7px;
    background: #939ef9;
    border-radius: 1px;
    content: ' ';
  }

  .item a {
    width: 100%;
    height: 100%;
    padding: 16px 0;
  }

  .item a:hover {
    font-weight: var(--bold) !important;
  }
} */
