.button {
  width: 390px;
  height: 52px;
  color: var(--white);
  font-weight: var(--bold);
  background: #688aea;
  border-radius: 2px;
  outline: none;
  box-shadow: 0px 13px 19px rgba(42, 44, 246, 0.1);
}

.button:hover {
  background: #6977b4;
}

.small {
  width: 334px;
}

@media (max-width: 1919px) {
  .button {
    width: 255px;
    height: 39px;
    border-radius: 5px;
  }
  .small {
    width: 235px;
  }
}

@media (max-width: 1365px) {
  .button {
    width: 224px;
    /* height: 36px; */
    height: auto;
    border-radius: 4px;
  }
  .small {
    width: 203px;
    height: auto;
  }
}

@media (max-width: 767px) {
  .button {
    width: 100%;
  }
  .small {
    width: 100%;
  }
}
