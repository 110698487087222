.ErrorPage {
  height: calc(100vh - 100px - 500px);
  /* padding-top: 10px; */
  margin: auto;
  padding: var(--paddingSectionTop) var(--paddingSectionLeft);
  padding-top: calc(var(--paddingSectionTop) * 1.5);
  padding-bottom: 0;
}
.text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 25px;
}
