.container {
  position: relative;
  margin: auto;
  margin-top: calc(var(--margin) * 0.5px);
  /* padding: var(--marginPx) 60px; */
  padding: calc(var(--paddingSectionTop) * 2) var(--paddingSectionLeft);
  overflow: hidden;
  background-color: #f9f9f9;
  background-image: url('../../images/FormContactUs.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: background-position 0.1s linear;
  will-change: background-position;
}

.container::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  content: '';
}

.header,
.grid {
  position: relative;
  z-index: 2;
}

.imgbox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--mainGapPx);
  background-color: rgba(255, 255, 255, 0.5);
}

.h2 {
}

.grid {
  display: grid;
  grid-template-areas:
    'item1 item2 . .'
    '. . item3 item4'
    'item5 item6 item7 item8';
  grid-template-rows: repeat(3, 1fr);
  /* grid-template-columns: repeat(4, 434px); */
  grid-template-columns: repeat(4, 1fr);
  gap: var(--mainGapPx);
}

.item:nth-child(1) {
  grid-area: item1;
}

.item:nth-child(2) {
  grid-area: item2;
  transform: translateX(-100%);
  opacity: 0;
  transition: all 1s ease;
  /* transition: opacity 1s ease, transform 1s ease;  */
}

.item:nth-child(3) {
  grid-area: item3;
}

.item:nth-child(4) {
  grid-area: item4;
  transform: translateX(-100%);
  opacity: 0;
}

.item:nth-child(5) {
  grid-area: item5;
  transform: translateX(100%);
  opacity: 0;
}

.item:nth-child(6) {
  grid-area: item6;
}

.item:nth-child(7) {
  grid-area: item7;
  transform: translateX(100%);
  opacity: 0;
}

.item:nth-child(8) {
  grid-area: item8;
}

.item:nth-child(2).visible,
.item:nth-child(4).visible {
  transform: translateX(0);
  opacity: 1;
  animation: slideInFromLeft 1s linear forwards;
}

.item:nth-child(5).visible,
.item:nth-child(7).visible {
  transform: translateX(0);
  opacity: 1;
  animation: slideInFromRight 1s linear forwards;
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* .item:nth-child(3){
grid-column: 3;
grid-row: 2;
} */
.item {
  border-radius: 10px;
}

.item img {
  width: 100%;
  /* height: 100%; */
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: calc(var(--mainGapPx) * 2);
  background: #f8f8f8;
  transition: all 0.3s;
}

.cardHeader {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
}

.description {
  /* width: 105%; */
  width: 100%;
  margin: calc(var(--mainGapPx) * 1) 0;
  font-weight: 400;
  font-size: 24px;
  font-family: Montserrat;
  line-height: calc(24px * 1.2);
  letter-spacing: -2%;
}

.id {
  font-weight: 400;
  font-size: 160px;
  font-family: Montserrat;
  line-height: 160px;
  letter-spacing: -4%;
  opacity: 0.05;
  user-select: none;
}

.card:hover {
  background-color: var(--blue30);
}

.card:hover line,
.card:hover path {
  stroke: white;
}

.card:hover g {
  opacity: 1;
}

.card:hover .title,
.card:hover .description,
.card:hover .id {
  color: white !important;
  border-color: white;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: calc(var(--paddingSectionTop) * 2);
  color: white;
}

@media (max-width: 1690px) {
  .description {
    font-size: 20px;
  }
}

@media (max-width: 1440px) {
  .grid {
    gap: calc(var(--mainGapPx) * 0.5);
  }

  .card {
    /* padding: calc(var(--mainGapPx) * 0.5); */
  }

  .description {
    margin: calc(var(--mainGapPx) * 1) 0;
  }

  .id {
    font-size: 100px;
    line-height: 92px;
  }

  .arrow {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 1300px) {
  .grid {
    display: grid;
    grid-template-areas:
      'item1 item2 .'
      'item3 item4 .'
      '. item5 item6'
      '. item7 item8';
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(3, 1fr);
  }

  .description {
    margin: calc(var(--mainGapPx) * 0.5) 0;
    line-height: 20px;
  }
}
@media (max-width: 950px) {
  .grid {
    display: grid;
    grid-template-areas:
      'item1 item2'
      'item3 item4'
      'item5 item6'
      'item7 item8';
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
  .header {
    flex-direction: column;
    gap: 20px;
    /* justify-content: start; */
    align-items: start;
    margin-bottom: calc(var(--paddingSectionTop) * 1);
  }
  .h2 {
    order: 2;
  }
}
@media (max-width: 650px) {
  .grid {
    display: grid;
    grid-template-areas:
      'item1'
      'item2'
      'item3'
      'item4'
      'item6'
      'item5'
      'item8'
      'item7';
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: 1fr;
  }
}
