.button {
  display: block;
  padding-bottom: 6px;
  color: var(--blue30);
  font-weight: 500;
  border-bottom: 1px solid var(--blue30);
}

a.button {
  display: inline-block;
  width: max-content;
  text-decoration: none;
}

.outline.white {
  padding: 10px 20px;
  color: white;
  border: 1px solid white;
  border-radius: 100px;
}

.outline.white svg {
  width: 12px;
  height: 12px;
  margin-left: 10px;
}

.outline.white line,
.outline.white path {
  stroke: white;
}
.outline.white:hover {
  background: var(--blue30);
  border: 1px solid var(--blue30);
}
