:root {
  /* note: для 1920 = 30 */
  /* --index: calc(1vw+1vh); */
  --index: calc(0.75vw + 0.75vh);

  --margin: 60;
  --marginPx: calc(var(--margin) * 1px);
  --mainGap: 20;
  --mainGapPx: calc(var(--mainGap) * 1px);

  --light: 300;
  --normal: 400;
  --medium: 600;
  --bold: 700;

  --gray: #f9f9f9;
  --light-gray: rgba(255, 255, 255, 0.9);
  --bright-gray: #c4c4c4;
  --dark-gray: #646464;
  --light-black: #2c2c2c;
  --transparent-gray: rgba(255, 255, 255, 0.3);
  --hovered-gray: rgba(255, 255, 255, 0.1);
  --white: #ffffff;
  --black: #000000;
  --blue: #688aea;
  --blue30: #4455a1;
  /* --light-blue: #d2dcf9;
  --hovered-blue: rgba(210, 220, 249, 0.6); */
  --dark-blue: #6977b4;
  /* --dark-blue: #313c6f; */
  --green: #3cb276;
  --red: #d94061;

  /* без анимации */
  --paddingSectionTop: 80px;
  /* с шапкой */
  /* --paddingSectionTop: 0px; */
  /* без шапки */
  /* --paddingSectionTop: 20px; */
  --paddingSectionLeft: 60px;

  --h2Size: 58px;
  /* --h2Size: 43px; */
  /* note: для анимации меньше */
  /* --defaultSize: 18px; */
  --defaultSize: 16px;
  /* --defaultSize: 24px; */
  --sSize: 24px;
  --lgSize: 32px;
  overflow-x: hidden;
  /* --defaultSize: 15px;
  --lgSize: 30px; */
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  width: 100vw;
  min-width: 320px;
  overflow-x: hidden;
  overflow-x: hidden;
  font-weight: var(--light);
  font-size: 16px;
  font-size: var(--defaultSize);
  font-family: 'Montserrat', sans-serif;
  line-height: 1.25;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h3,
h4 {
  line-height: 100%;
}

h2 {
  /* font-size: 48px; */
  font-weight: 500;
  font-size: var(--h2Size);
  font-family: Montserrat;
  line-height: 100%;
  /* text-transform: uppercase; */
}

h2 span,
h1 span {
  color: #b0cbea;
}

button,
input {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  text-transform: inherit;
  background-color: inherit;
  border: none;
  cursor: pointer;
}

footer {
  transition: transform 0.3s;
}

/* @media screen and (max-width: 1920px) {
  body {
    zoom: 0.75;
  }
} */
@media (max-width: 1920px) {
  :root {
    --h2Size: 48px;
  }

  /* h2 {
    font-size: 48px;
  } */
}

@media (max-width: 1690px) {
  :root {
    --paddingSectionLeft: 40px;
    --defaultSize: 15px;
    /* --mainGap: 18; */
  }
}

@media (max-width: 1500px) {
  :root {
    --paddingSectionTop: 50px;
    --paddingSectionLeft: 30px;
    --defaultSize: 14px;
  }
}

@media (max-width: 1440px) {
  :root {
    /* --defaultSize: 16px; */
    --lgSize: 23px;
    --sSize: 20px;
  }

  h2 {
    /* margin-bottom: calc(var(--mainGapPx) * 0.75); */
    font-size: 33px;
  }
}

@media (max-width: 1365px) {
  :root {
    font-size: 10px;
  }
}

@media (max-width: 1024px) {
  :root {
    --defaultSize: 12px;
    --lgSize: 18px;
    --paddingSectionTop: 50px;
    --paddingSectionLeft: 40px;
  }

  h2 {
    font-size: 22px;
  }
}
